body::-webkit-scrollbar {
  width: 1em;
  border-radius: 50%;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  outline: 1px solid #6c7881;
  border-radius: 2em;
}

/*FireFly effect*/

